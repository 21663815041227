<template>
  <div class="evaluate">
    <div class="fz-16 bold mg-b10">商家综合评价</div>
    <div class="goods-score flex sctp-pad-tb15 flex-sb">
      <div class="header-scope">
        <div class="header-scope__item">
          <span>沟通能力</span><span>{{ productRate.communicatioScore }}</span>
        </div>
        <div class="header-scope__item">
          <span>完成程度</span><span>{{ productRate.completeStatusScore }}</span>
        </div>
        <div class="header-scope__item">
          <span>交付质量</span><span>{{ productRate.deliveryQualityScore }}</span>
        </div>
        <div class="header-scope__item">
          <span>服务态度</span><span>{{ productRate.serviceAttitudeScore }}</span>
        </div>
        <div class="header-scope__item">
          <span>技术能力</span><span>{{ productRate.technicalAbilityScore }}</span>
        </div>
      </div>
      <template v-if="isInit">
        <evaluate-radar :source-data="[
                {item: '交流', score: productRate.communicatioScore},
                {item: '完成度', score: productRate.completeStatusScore},
                {item: '质量', score: productRate.deliveryQualityScore},
                {item: '服务', score: productRate.serviceAttitudeScore},
                {item: '技术', score: productRate.technicalAbilityScore},
            ]"></evaluate-radar>
      </template>
    </div>
    <div class="evaluate">
      <div class="fz-16 bold mg-b10">用户商品评价111</div>
      <template v-if="comments && comments.length > 0">
        <template v-for="(comment, index) in comments">
          <div class="evaluate-item sctp-flex" :key="Comment.commid">
            <div class="avatar sctp-flex sctp-flex-column sctp-mar-r20"
                 style="align-items: center;">
              <el-avatar class="sctp-mar-b15" :size="65" :src="Comment.avatar"
                         fit="cover"></el-avatar>
              <el-rate :disabled="true" :value="Comment.avgRate"></el-rate>
            </div>
            <div class="evaluate-content sctp-flex-item-1">
              <div>
                <span class="sctp-mar-r20">{{ Comment.username }}</span>
                <span>{{ Comment.createtime | dateString('yyyy-MM-dd HH:mm:ss') }}</span>
              </div>
              <div class="sctp-info sctp-pad-tb10">
                {{ Comment.content }}
              </div>
              <div class="sctp-pad-tb10">
                <template v-for="picture in Comment.pictures">
                  <el-image
                    :key="picture"
                    class="sctp-mar-r10"
                    style="width: 100px; height: 100px"
                    :src="picture" fit="contain"
                    :preview-src-list="Comment.pictures">
                  </el-image>
                </template>
              </div>
              <template v-if="Comment.isSeller">
                <template v-if="!Comment.reply || Comment.reply.length === 0">
                  <template
                    v-if="Comment.replyFlag"
                  >
                    <el-input
                      type="textarea"
                      :rows="5"
                      :placeholder="'回复 ' + Comment.username"
                      v-model="Comment.replyContent">
                    </el-input>
                    <div class="sctp-mar-t10">
                      <el-button @click="Comment.replyFlag = false" size="small">取消</el-button>
                      <el-button :disabled="!Comment.replyContent || !Comment.canClick"
                                 @click="onReplyClick(Comment)" size="small" type="primary"
                      >
                        发送
                      </el-button>
                    </div>
                  </template>
                  <a v-else @click="Comment.replyFlag = true">回复</a>
                </template>
              </template>
              <template v-if="Comment.reply">
                <template v-for="replyItem in Comment.reply">
                  <div :key="replyItem.createtime">
                    <span class="sctp-mar-r20">商家回复：{{ Comment.username }}</span>
                    <span>{{ replyItem.createtime | dateString('yyyy-MM-dd HH:mm:ss') }}</span>
                  </div>
                  <div :key="replyItem.createtime" class="sctp-info sctp-pad-tb10">
                    {{ replyItem.replycontent }}
                  </div>
                </template>
              </template>
            </div>
          </div>
          <template v-if="index !== comments.length - 1">
            <div class="divider"></div>
          </template>
        </template>
        <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white sctp-mar-t10">
          <el-pagination
            :current-page="pageConfig.page"
            :page-size="pageConfig.limit"
            @size-change="pageConfig.handleSizeChange"
            @current-change="pageConfig.handleCurrentChange"
            :page-sizes="pageConfig.pageSizes"
            layout="prev, pager, next, total, sizes"
            :total="pageConfig.total"
          ></el-pagination>
        </div>
      </template>
      <template v-else>
        <div style="height: 100px;line-height: 100px;" class="sctp-tc">暂无评价</div>
      </template>
    </div>
  </div>
</template>

<script>
import {goods} from "@/apis/index";

const config = require('@/resources/js/config');
export default {
  name: '',
  components: {
    EvaluateRadar: () => import('@CMP/viser/EvaluateRadar'),
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  watch: {
    id: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.getComment()
        }
      },
      deep: true
    }
  },
  data() {
    return {
      isInit: false,
      comments: [],
      productRate: {
        communicatioScore: 0.0,
        completeStatusScore: 0.0,
        deliveryQualityScore: 0.0,
        serviceAttitudeScore: 0.0,
        technicalAbilityScore: 0.0,
      },
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        handleSizeChange: (limit) => {
          this.pageConfig.page = 1;
          this.pageConfig.limit = limit;
          this.getComment();
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getComment();
        }
      },
    }
  },
  methods: {
    /**
     * 获取商品评价
     */
    getComment() {
      let {pageConfig: {page, limit}} = this;
      goods.getComment({
        id: this.id,
        type: this.type,
        userId: (this.user && this.user.userId) || null,
        page: page,
        limit: limit
      }).then(res => {
        const {data, productRate, count} = res;
        data.forEach(item => {
          // 回复标记
          item.replyFlag = false;
          item.canClick = true
        });
        this.comments = data;
        this.pageConfig.total = count;
        this.isInit = true;
        if (productRate) {
          Object.keys(productRate).forEach(key => {
            this.productRate[key] = productRate[key] || 0.0;
          })
        }
      })
    },
    /**
     * 商家回复评论
     */
    onReplyClick(comment) {
      Comment.canClick = false;
      let {replyContent} = Comment;
      replyContent = replyContent.trim();
      if (!replyContent) {
        Comment.canClick = true;
        return false
      }
      this.doCommitReply(Comment.commid, Comment.replyContent)
      .then((res) => {
        const {data} = res;
        const reply = Comment.reply || [];
        reply.push(data);
        Comment.reply = reply;
        Comment.replyContent = '';
        Comment.canClick = true;
        Comment.replyFlag = false
      })
      .catch(() => {
        Comment.canClick = true
      })
    },
    doCommitReply(id, content) {
      return this.$request.post({
        reqcode: '1092',
        reqdesc: '商家回复',
        commid: id,
        replycontent: content
      })
    }
  },
  computed: {},
  mounted() {
  },
  beforeMount() {
    this.getComment()
  }
}
</script>

<style scoped lang="scss">
.evaluate /deep/ {
  .header-scope {
    display: flex;
    .header-scope__item {
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      text-align: center;
      span:nth-child(2) {
        margin-top: 5px;
      }
    }
  }

  .evaluate-item + .evaluate-item {
    margin-top: 10px;
  }

  .evaluate-item .el-rate__icon {
    margin-right: 0;
  }

  .divider {
    height: 1px;
    border-bottom: #dddddd 1px dashed;
    margin: 10px 0;
    width: 100%;
  }
}
</style>
